.ant-select-selector {
    width: 100%;
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.site-custom-tab-bar {
    z-index: 1;
    background: #3f8cff;
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
    border-top: 2px solid blue;
} */

.tab_pane {
    border: solid 1px;
    border-radius: 10px;
    border-color: #ffffff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
}

.tab_pane_active {
    border: solid 1px;
    border-radius: 10px;
    border-color: #3f8cff;
    background-color: #ffffff;
    /* background-color: transparent; */
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.9
}

/* .ant-tabs-ink-bar {
    height: 17px;
}
 */
/* .ant-tabs-tab-active {
    border-radius: 10px;
    border: 1px solid ;
    border-color: #3f8cff;
    padding: -10px -15px !important;
} */
.ant-pro-table-list-toolbar-title{
    font-size: 17px;
    font-weight: 550;
    margin-left: 20px;
}
