.ant-pro-card{
    background:'#f1f6fa'
}

.ant-pro-core-field-label{
    font-weight: 550;
}

/* .ant-pro-core-field-dropdown-label{ */
.ant-pro-form-light-filter-item{
    border: 1px solid;
    border-radius: 10px !important;
    border-color:#a8beed !important;
    padding: 0 10px;
}

/* ant-pro-core-field-label .anticon anticon-down ant-pro-core-field-label-icon ant-pro-core-field-label-arrow{ */
.ant-pro-core-field-label-arrow{
    font-size: 10px;
    margin-top: 3px;
}

.ant-popover-inner{
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.ant-btn-round{
    border-radius: 10px 
}

.ant-btn-sm{
    border-radius: 8px 
}

/* :where(.css-dev-only-do-not-override-1igyz53).ant-pro-card .ant-pro-card-body {
    background:'#f1f6fa !important';
} */

.ant-pro-card{
    background-color:transparent;
    /* background:'#f1f6fa !important'; */
}

.ant-table{
    background-color:transparent;
    /* background:'#f1f6fa !important'; */
}

/* .ant-table-row{
    border: 1px solid red;
    border-radius: 10px;
    margin: 20px;
    background: white;
} */

.ant-pro-card .ant-pro-card-body {
    /* display: block;
    box-sizing: border-box;
    height: 100%; */
    padding-inline: 0;
    /* padding-block: 16px; */
}

.ant-list-item {
    align-items: flex-start;
}

.ant-pro-list-row {
    background: white;
    /* border: 2px solid; */
    border-radius: 20px;
    margin: 15px 0;
    padding: 20px 20px;
}

.ant-list-item-meta-title {
    color: #00000073;
}

.ant-list-item-meta-description {
    color: #000000D9;
}

.ant-pro-list-row-header {
    /* width: 100px; */
    /* flex: 0; */
    display: none;
    /* justify-content: flex-start; */
}

.ant-pro-list-row-content {
    margin-inline: 0;
}

.my-tb-row {
    /* background: white; */
    /* border: 2px solid; */
    border-radius: 10px;
    margin: 15px 0;
    /* padding: 20px 20px; */
}

.ant-list-item-action {
    margin-left: 10px;
}

.m-button-blue{
    background-color: #ebf2fc;
    border-color: #ebf2fc;
    color: #1296db;
    box-shadow: none;
}

/* .m-button-blue:hover{
    background-color: white;
    border-color: #1296db;
    color: #1296db;
    box-shadow: none;
} */

.m-button-green{
    background-color: rgb(224, 249, 242);
    border-color: rgb(224, 249, 242);
    color: rgb(0, 208, 151);
    box-shadow: none;
}

.m-button-green:focus, .m-button-green:hover, .m-button-green:active, .m-button-green:visited{
    background-color: white;
    border-color: rgb(0, 208, 151);
    color: rgb(0, 208, 151);
    box-shadow: none;
}

.m-button-red{
    background-color: rgb(255, 235, 235);
    border-color: rgb(255, 235, 235);
    color: rgb(255, 102, 102);
    box-shadow: none;
}

.m-button-red:focus, .m-button-red:hover, .m-button-red:active, .m-button-red:visited{
    background-color: white;
    border-color: rgb(255, 102, 102);
    color: rgb(255, 102, 102);
    box-shadow: none;
}

.ant-btn-sm {
    padding: 0px 15px;
}

.ant-select-dropdown{
    border-radius: 8px;
}

.ant-input-affix-wrapper {
    border-radius: 8px;
    border-color: #a8beed;
}

/* .ant-pagination-prev
{
    border-radius: 6px;
}

.ant-pagination-next
{
    border-radius: 6px;
    background-color: red;
}

.ant-pagination-item-link
{
    border-radius: 20px;
    background-color: #1296db;
} */

.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link ,.ant-pagination-item
{
    border-radius: 6px;
}

