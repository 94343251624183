body,html{
    margin: 0 0;
    padding: 0 0;
}

/* #box{
    -webkit-display:flex;
    display: flex;
    flex-direction: row ;
    width: 100%;
    height: 600px;
    background-color: #fff;
    border: 1px solid #ccc;
} */
#phone{
    flex: 9;
    overflow: hidden;
}



#box-tool{
    -webkit-display:flex;
    display: flex;
    flex-direction: column ;
    width:50px;
    min-height:98%;
    border-left: 1px solid #ccc; 
}


#box-tool-close,
#box-tool-jietu,
#box-tool-screen,
#box-tool-home,
#box-tool-menu,
#box-tool-back,
#box-tool-play,
.box-tool-video-open,
.box-tool-video-close{
  width: 32px;
  height: 32px;
  margin: 5px 8px;
}
#box-tool-close{
    margin-top: 10px;
    background-image: url('./img/close.png');
    transform:scale(0.5,0.5);
   
}
#box-tool-jietu{
    /* margin-top: 50px; */
    background-image: url('./img/jietu.png');
    transform:scale(0.8,0.8);
}

#box-tool-screen{
    background: url('./img/screen.png') no-repeat 2px;
    transform:scale(0.8,0.8);
}

#box-tool-home{
    background-image: url('./img/home.png');
    transform:scale(0.8,0.8);
}

#box-tool-menu{
    background-image: url('./img/menu.png');
    transform:scale(0.8,0.8);
}

#box-tool-back{
    background-image: url('./img/back.png');
    transform:scale(0.7,0.7);
}

#box-tool-play{
    background-image: url('./img/play1.png');
    transform:scale(0.9,0.7);
}
.box-tool-video-open{
    background-image: url('./img/videoopen.png');
    transform:scale(0.7,0.8);
}

.box-tool-video-close{
    background-image: url('./img/videoclose.png');
    transform:scale(0.7,0.8);
}



.zhilianModal .ant-modal-header {
    display: none;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

.zhilianModal .ant-modal-footer {
    display: none;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

.playModal .ant-modal-footer {
    display: none;
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
}

#playTime-tool-play,
#playTime-tool-stop,
#playTime-tool-suspend{
    display: inline-block;
    width: 32px;
    height: 32px;
    margin-left: -3px;
}

#playTime-tool-play{
    background-image: url('./img/play.png');
    transform:scale(0.7,0.7);
}

#playTime-tool-stop{
    background-image: url('./img/stop.png');
    transform:scale(0.6,0.6);
}

#playTime-tool-suspend{
    background-image: url('./img/suspend.png');
    transform:scale(0.7,0.7);
}

.playTimeModal span{
    float:left;
    margin:0 10px;
    line-height: 32px;
    
}
.roundStop{
    display: inline-block;
    float: left;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-left: 10px;
    background-color: #ccc;
    border-radius: 50%;
}
.round{
    display: inline-block;
    float: left;
    width: 10px;
    height: 10px;
    margin-top: 10px;
    margin-left: 10px;
    background-color: red;
    border-radius: 50%;
    -webkit-animation: twinkling 1.5s infinite ease-in-out 
}
.animated{ 
    -webkit-animation-duration: 1.5s; 
    animation-duration: 1.5s; 
    -webkit-animation-fill-mode: both; 
    animation-fill-mode: both 
} 
@-webkit-keyframes twinkling{ 
    0%{ 
        opacity: 0.5; 
    } 
    100%{ 
    opacity: 1; 
    } 
    } 
@keyframes twinkling{ 
    0%{ 
        opacity: 0.5; 
    } 
    100%{ 
    opacity: 1; 
    } 
}

.playModal .ant-card-body {
    padding: 0 !important;
}

.playModal .ant-table-thead{
    display: none !important;
}

/* .ant-table-tbody > tr > td {
    padding: 7px 7px !important;
    border-bottom:0 solid #f0f0f0 !important;
    transition: background 0.3s;
} */

.playModal .ant-divider-horizontal {
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 10px 0;
}

.zhilian_modal .ant-modal-body {
    padding: 15px 0 0 0 !important;
}

.zhilian_win .ant-modal-content{
    background-color: red;
    box-shadow: none;
}

.toupingwin {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    /* border-radius:20px; */
    background-color: transparent;
    /* background:linear-gradient(#ff0000,#0000ff); */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    /* bottom: 0px; */
    margin: auto;
}

.zhilian_title{
    color: rgb(63, 140, 255);
    text-align:left;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
}
