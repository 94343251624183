.ant-select-selector {
    width: 100%;
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.phoneClass {
    width: 240px;
    /* height: 540px; */
    /* padding-bottom: 10px; */
    padding-bottom: 40px;
    border: solid 1px;
    border-radius: 15px;
    border-color:#a8beed;
    margin-top: 20px;
}

.phoneSmarll {
    width: 200px;
    height: 285px;
    border: solid 1px;
    border-radius: 15px;
    border-color:#cbe0ff;
    background-color: #ebf2fc;
    margin-left: 20px;
    margin-top: 20px;
}

.phoneHole {
    width: 50px;
    height: 10px;
    border-radius: 5px;
    background-color: #a9bfee;
    margin-left: 75px;
    margin-top: 20px;
}

.phoneText {
    /* left: 50%;
    top: 50%; */
    margin-top: 70px;
    text-align: center;
    /* font-weight: 550; */
}

.toupingText {
    font-size:1.6em;
    /* color:rgb(21,72,152); */
    font-weight:550;
    margin-top: 90px;
}

.download {
    /* border-radius: 10px; */
    background-color: rgb(176,212,255);
    color: black;
}

.download:focus, .download:hover, .download:active, .download:visited {
    /* border-radius: 10px; */
    background-color: rgb(186, 217, 255);
    color: black;
    border-color: rgb(186, 217, 246);
}

.site-custom-tab-bar {
    z-index: 1;
    background: #3f8cff;
}

/* .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active::before {
    border-top: 2px solid blue;
} */

/* .ant-tabs-tab-active  { */
    /* border-bottom: thick solid #3f8cff; */
    /* border-style:ridge; */
    /* border-bottom-width: 20px; */
    /* border-radius: 20px; */
/* } */

.tab_pane {
    border: solid 1px;
    border-radius: 10px;
    border-color: #ffffff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.6
}

.tab_pane_active {
    border: solid 1px;
    border-radius: 10px;
    border-color: #3f8cff;
    background-color: #ffffff;
    padding: 6px 15px;
    font-weight: 550;
    opacity: 0.9;
    /* transition: width 0.3s, left 0.3s, right 0.3s; */
}

.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    padding: 16px 0 15px;
}

/* .ant-tabs-tab-active {
    border-radius: 10px;
    border: 1px solid ;
    border-color: #3f8cff;
    padding: -10px -15px !important;
} */

.ant-modal-confirm .ant-modal-confirm-btns{
    text-align: center;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
    margin-left: 30px;
}


.touping {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    /* border-radius:20px; */
    /* background-color: transparent; */
    /* background:linear-gradient(#ff0000,#0000ff); */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

.ant-checkbox-inner{
    border-color: rgb(63,140,255);
}

/* .ant-tabs-top .ant-tabs-ink-bar-animated:before {
    content: " ";
    background: url(~@/assets/imgs/case_nav_ink_bar.png) no-repeat 50%;
    background-size: cover;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 50%;
    top: 38px;
    transform: translate(-50%, -50%);
 } */

/* .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 6px;
    border-radius: 15px; */
    /* width: 50%; */
    /* padding: 0 25%; */
/* } */

.ant-tabs-ink-bar{
    background-color: transparent;
}
.ant-tabs-top > .ant-tabs-nav{
    margin-bottom: 0;
}
.cust_tab_bar_active{
    background-color: rgb(63,140,255);
    height: 6px;
    border-radius: 10px;
    margin-top: 10px;
    width: 60%;
    margin-left: 20%;
    /* transition: width 0.3s, left 0.3s, right 0.3s; */
}

.cust_tab_bar{
    background-color: transparent;
    height: 6px;
    border-radius: 10px;
    margin-top: 10px;
    width: 60%;
    margin-left: 20%;
}
