.ant-select-selector {
    width: 100%;
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.ant-input {
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.ant-modal-title{
    font-weight: 550;
}

/* .zhilian_modal .ant-modal-body{
    padding: 30px 0 0 0 !important;
}

.zhilian_modal .ant-modal-footer{
    padding-top: 0px !important;
} */

.detal_title {
    color: rgba(0, 0, 0, 0.7);
}

