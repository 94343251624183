


#box {
    display: flex;
    width: 95%;
    height: 95%;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    border-radius:20px;
    background-color: white;
    /* background:linear-gradient(#ff0000,#0000ff); */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

#box_register {
    display: flex;
    width: 95%;
    height: 95%;
    justify-content: center;
    align-items: center;
    /* padding: 50px; */
    /* border-radius:20px; */
    /* background-color: white; */
    /* background:linear-gradient(#ff0000,#0000ff); */
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
}

#register{
    width: 400px;
    height:300px;
    border:1px solid #ccc;
    /* // top:50%; */
    margin-top:150px
}

#title{
    line-height: 70px;
    font-weight: 550;
    margin-top: 20px;
    font-size: 1.5em;
}

.loginForm{
    width: 40%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: 0 auto;
}

.loginForm .ant-form-item-explain-error{
    text-align: left;
}

#box_register .loginForm{
    width: 40%;
}

.loginForm .ant-form-item-label > label {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 550;
}

#box_register .ant-form-item{
    margin-bottom: 10px;
}

.box_register #title{
    padding: 0;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: white;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: white;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(255, 255, 255, 0.6);
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgb(63,140,255);
}

.ant-steps-item-wait .ant-steps-item-icon {
    border: 2px solid rgba(255,255,255,0.6);
    background-color: rgb(63,140,255);
}

.ant-steps-item-process .ant-steps-item-icon {
    border: 2px solid white;
    background-color: rgb(121,175,255) !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    color: rgb(121, 175, 232);
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: white;
}

/* .ant-upload-drag-icon{
    width:30px;
    height:30px;
    border-radius:30px;
    background:rgb(191,191,191);
} */

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: white;
    font-size: 24px;
    width: 30px;
    height: 30px;
    background: rgb(191,191,191);
    border-radius: 30px;
    padding: 3px;
}

.ant-upload.ant-upload-drag p.ant-upload-text{
    color: rgba(0, 0, 0, 0.4);
    font-size: small;
}

.ant-upload.ant-upload-drag p.ant-upload-hint{
    color: rgba(0, 0, 0, 0.4);
    font-size: small;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    /* display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1; */
    content: '';
}