.top-right{
    /* position: fixed; */
    margin-top: 4px;
    float: right;
    margin-right: -20px;
    height: 70%;
    padding: 10px 20px;
    border-radius: 10px;
    display:flex;justify-content: center; align-items:center;
  }

.userSuper{
    /* position: fixed; */
    margin-left: 20px;
    margin-top: 8px;
    margin-right: -20px;
    background-color: white;
    height: 42px;
    padding: 10px 20px;
    border-radius: 10px;
    display:flex;justify-content: center; align-items:center;
    color: black;
  }


.msgbutton{
  /* position: fixed; */
  border-radius:10px;
  background-color:"#fff";
  height:42px;
  margin-top:9px
}

.ant-modal-content{
  border-radius: 10px;
  padding-top: 10px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
width:5px;
display: none;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
-webkit-box-shadow:inset006pxrgba(0,0,0,0.3);
border-radius:10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
border-radius:10px;
background:rgba(0,0,0,0.1);
-webkit-box-shadow:inset006pxrgba(0,0,0,0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
background:rgba(0,0,0,0.1);
}


.ant-dropdown-menu-item-active {
  color: #3f8cff;
  background-color: rgb(236, 243, 255) !important;
}
/* 
.ant-select-item-option-active {
  color: #3f8cff;
  background-color: rgb(230, 247, 255) !important;
} */

.ant-form-item-label > label{
  color: rgba(0, 0, 0, 0.6);
}

.ant-radio-button-wrapper{
  border-radius: 8px !important;
  background: rgb(234,234,234) !important;
  border-color: rgb(234,234,234) !important;

}
.ant-radio-button-wrapper-checked {
  border-radius: 8px !important;
  background: rgb(232,241,255) !important;
  border-color: rgb(232,241,255) !important;
}

.ant-modal-body {
  padding: 24px 40px;
}

.ant-modal-footer {
  padding: 5px 20px 30px;
  border-top: 0px;
}
