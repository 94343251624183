.ant-select-selector {
    width: 100%;
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.ant-input {
    border-radius: 10px !important;
    border-color:#a8beed !important;
}

.phoneClass_bc {
    width: 200px;
    height: 480px;
    border: solid 1px;
    border-radius: 15px;
    border-color:#a8beed;
    margin-top: 20px;
}

.phoneSmarll_bc {
    width: 160px;
    height: 250px;
    border: solid 1px;
    border-radius: 15px;
    border-color:#cbe0ff;
    background-color: #ebf2fc;
    margin-left: 20px;
    margin-top: 25px;
}

.phoneHole_bc {
    width: 50px;
    height: 10px;
    border-radius: 5px;
    background-color: #a9bfee;
    margin-left: 55px;
    margin-top: 10px;
}

.phoneTeText {
    /* left: 50%;
    top: 50%; */
    margin-top: 75px;
    text-align: center;
    /* font-weight: 550; */
}

.ant-modal-title{
    font-weight: 550;
}

/* .zhilian_modal .ant-modal-body{
    padding: 30px 0 0 0 !important;
}

.zhilian_modal .ant-modal-footer{
    padding-top: 0px !important;
} */
